import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppState } from '../../store';
import { setAuthState, forgotPassword, getForgotPasswordId, newPassword } from '../../store/auth/actions';
import { AuthState, UserType } from '../../store/auth/types';
import { SystemState } from '../../store/system/types';
import { setSystemState } from '../../store/system/actions';

// Global Components
import Layout from '../common/Layout';
import Input from '../common/Input';

// Local Components
import { 
    ErrorText,
    SubmitButton,
    ForgotPasswordText,
    ForgotPasswordContent,
    ForgotPasswordContainer,
    ForgotPasswordInputContainer,
    ResetPasswordAdornmentContainer
} from './fragments/ForgotPasswordComponents';

// Material UI
import Box from '@material-ui/core/Box';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';

// Utils
import jwt from 'jsonwebtoken';

interface ResetPasswordProps {
    auth: AuthState;
    system: SystemState;
    setAuthState: typeof setAuthState;
    forgotPassword: typeof forgotPassword;
    newPassword: typeof newPassword;
    getForgotPasswordId: typeof getForgotPasswordId;
    setSystemState: typeof setSystemState;
}

interface decodedType {
    name: string;
    exp: string;
    iap: string;
    user: UserType;
}

class ResetPassword extends Component<ResetPasswordProps> {

    componentDidMount = () => {
        this._setToken();
    }

    _onPressSubmit = () => {
        if (this._validate()) {
            this._verifyToken();
        } else {
            this.props.setSystemState({
                snackBarIsOpen: true,
                snackBarMessage: 'Invalid Password',
                snackBarType: 'error'
            })
        }
    }

    _validate = () => {
        const { resetPasswordNewPassword, resetPasswordConfirmNewPassword, resetPasswordValidMinimum, resetPasswordValidMaximum, resetPasswordValidMixCase, resetPasswordValidNumber } = this.props.auth;
        let valid = true;
        
        this.props.setAuthState({
            resetPasswordErrorInputs : {
                newPassword: {
                    error: !resetPasswordNewPassword || resetPasswordNewPassword.length < 8 || resetPasswordNewPassword.length > 36 ? true : false,
                    message: !resetPasswordNewPassword ? 'Please enter New Password' : resetPasswordNewPassword.length < 8 ? 'The Password is too short' : resetPasswordNewPassword.length > 36 ? 'The Password is too long' : ''
                },
                confirmPassword: {
                    error: !resetPasswordConfirmNewPassword || resetPasswordConfirmNewPassword !== resetPasswordNewPassword ? true : false,
                    message: !resetPasswordConfirmNewPassword ? 'Please enter Confirm Password' : (resetPasswordNewPassword && resetPasswordConfirmNewPassword !== resetPasswordNewPassword) ? 'Password and the Confirmed Password does not match' : ''
                }
            }
        })

        if (!resetPasswordNewPassword || resetPasswordNewPassword.length < 8 || resetPasswordNewPassword.length > 36) valid = false;
        if (!resetPasswordConfirmNewPassword || resetPasswordConfirmNewPassword !== resetPasswordNewPassword) valid = false;
        if (!resetPasswordValidMinimum || !resetPasswordValidMaximum || !resetPasswordValidMixCase || !resetPasswordValidNumber) valid = false;

        return valid;
    }

    _verifyToken = () => {
        let secretKey = process.env.REACT_APP_NEW_PASSWORD_KEY;
        if (secretKey) {
            let url = window.location.href;
            let token = url.substring(url.lastIndexOf('/') + 1);
            const { resetPasswordNewPassword, resetPasswordConfirmNewPassword } = this.props.auth;
            try {
                const decoded = (jwt.verify(token, secretKey) as unknown) as decodedType;
                if (decoded.name === 'JsonWebTokenError') {
                    this.props.setSystemState({
                        snackBarIsOpen: true,
                        snackBarMessage: 'JSON Web Token Error',
                        snackBarType: 'error'
                    })
                } else {
                    this.props.newPassword(decoded.user.id, resetPasswordNewPassword, resetPasswordConfirmNewPassword, token);
                }
            } catch (e) {
                if (e.message === 'jwt expired') {
                    this.props.setSystemState({
                        snackBarIsOpen: true,
                        snackBarMessage: 'Token Expired. Request for a new reset password',
                        snackBarType: 'error'
                    })
                }
            }
        } else {
            this.props.setSystemState({
                snackBarIsOpen: true,
                snackBarMessage: 'Invalid Token',
                snackBarType: 'error'
            })
        }
    };

    _setToken = () => {
        let secretKey = process.env.REACT_APP_NEW_PASSWORD_KEY;
        if (secretKey) {
            let url = window.location.href;
            let token = url.substring(url.lastIndexOf('/') + 1);
            if (token === 'resetpassword') {
                token = '';
            }
            if (token !== 'home') {
                try {
                    const decoded = (jwt.verify(token, secretKey) as unknown) as decodedType;
                    this.props.setAuthState({ resetPasswordUser: decoded.user })
                    this.props.getForgotPasswordId(decoded.user.id, token);
                } catch (e) {
                    if (e.message === 'jwt must be provided') {
                        this.props.setSystemState({
                            shallRedirect: true, 
                            redirectTo: '/login',
                            snackBarIsOpen: true,
                            snackBarMessage: 'JWT must be provided',
                            snackBarType: 'warning'
                        })
                    } else if (e.message === 'jwt malformed') {
                        this.props.setSystemState({
                            shallRedirect: true, 
                            redirectTo: '/login',
                            snackBarIsOpen: true,
                            snackBarMessage: 'JWT is malformed. Please contact your administrator',
                            snackBarType: 'warning'
                        })
                    }
                    else {
                        this.props.setSystemState({
                            shallRedirect: true, 
                            redirectTo: '/login',
                            snackBarIsOpen: true,
                            snackBarMessage: 'Invalid token receieved. Redirecting to login page',
                            snackBarType: 'warning'
                        })
                    }
                }
            }
        }
    };

    _onChangeInput = (property: string, value: string) => {
        this.props.setAuthState({
            [property]: value
        })
        if (property === 'resetPasswordNewPassword') {
            const { message } = this.props.auth.resetPasswordErrorInputs.newPassword;
            if (message === 'Please enter New Password') {
                if (value) this.props.setAuthState({ resetPasswordErrorInputs: { ...this.props.auth.resetPasswordErrorInputs, newPassword: { error: false, message: '' }}})
            } else if (message === 'The Password is too short') {
                if (value.length >= 8) this.props.setAuthState({ resetPasswordErrorInputs: { ...this.props.auth.resetPasswordErrorInputs, newPassword: { error: false, message: '' }}})
            } else if (message === 'The Password is too long') {
                if (value.length <= 36) this.props.setAuthState({ resetPasswordErrorInputs: { ...this.props.auth.resetPasswordErrorInputs, newPassword: { error: false, message: '' }}})
            }
        } else if (property === 'resetPasswordConfirmNewPassword') {
            const { message } = this.props.auth.resetPasswordErrorInputs.confirmPassword;
            if (message === 'Please enter Confirm Password') {
                if (value) this.props.setAuthState({ resetPasswordErrorInputs: { ...this.props.auth.resetPasswordErrorInputs, confirmPassword: { error: false, message: '' }}})
            } else if (message === 'Password and the Confirmed Password does not match') {
                if (value === this.props.auth.resetPasswordNewPassword) this.props.setAuthState({ resetPasswordErrorInputs: { ...this.props.auth.resetPasswordErrorInputs, confirmPassword: { error: false, message: '' }}})
            }   
        }
    } 

    _onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            this._onPressSubmit()
        }
    }

    _onChangePasswordInput = (property: string, text: string) => {
        const numberRegex : RegExp = /\d/;
        const mixCaseRegex : RegExp = /(?=.*[a-z])(?=.*[A-Z])/
        this._onChangeInput(property, text)
        this.props.setAuthState({
            resetPasswordValidMinimum: text.length >= 8 && text.length !== 0 ? true : false,
            resetPasswordValidMaximum: text.length <= 36 && text.length !== 0 ? true : false,
            resetPasswordValidMixCase: mixCaseRegex.test(text) && text.length !== 0 ? true : false,
            resetPasswordValidNumber: numberRegex.test(text) ? true : false
        })
    }

    render() {
        const { resetPasswordNewPassword, resetPasswordConfirmNewPassword, resetPasswordLoading, resetPasswordErrorInputs, isLoggedIn, resetPasswordValidMinimum, resetPasswordValidMaximum, resetPasswordValidNumber, resetPasswordValidMixCase } = this.props.auth;
        const passwordValid = (resetPasswordNewPassword && resetPasswordConfirmNewPassword) && resetPasswordNewPassword === resetPasswordConfirmNewPassword
        return (
            <Layout>
                {isLoggedIn ? <Redirect to="/home" />  :
                    <ForgotPasswordContainer>
                        <ForgotPasswordContent>
                            <ForgotPasswordText>Reset Password</ForgotPasswordText>
                            <Box display="flex" flexDirection="column" style={{ width: '100%', boxSizing: 'border-box', wordBreak: 'break-word', position: 'relative' }}>
                                <ForgotPasswordInputContainer>
                                    <Input 
                                        id="resetpassword-newpassword-input"
                                        type="password"
                                        label=""
                                        value={resetPasswordNewPassword}
                                        onChange={(e) => this._onChangePasswordInput('resetPasswordNewPassword', e.target.value)}
                                        placeholder="New Password"
                                        login={true}
                                        onKeyDown={this._onEnterPress.bind(this)}
                                    />
                                    { passwordValid &&
                                        <ResetPasswordAdornmentContainer>
                                            <CheckCircleIcon htmlColor="#4BB543" />
                                        </ResetPasswordAdornmentContainer>
                                    }
                                </ForgotPasswordInputContainer>
                                { resetPasswordErrorInputs.newPassword.error &&
                                    <ErrorText id="resetpassword-errortext-newpassword">{resetPasswordErrorInputs.newPassword.message}</ErrorText>
                                }
                            </Box>
                            <Box display="flex" flexDirection="column" style={{ width: '100%', boxSizing: 'border-box', wordBreak: 'break-word', position: 'relative' }}>
                                <ForgotPasswordInputContainer>
                                    <Input 
                                        id="resetpassword-confirmnewpassword-input"
                                        type="password"
                                        label=""
                                        value={resetPasswordConfirmNewPassword}
                                        onChange={(e) => this._onChangeInput('resetPasswordConfirmNewPassword', e.target.value)}
                                        placeholder="Confirm New Password"
                                        login={true}
                                        onKeyDown={this._onEnterPress.bind(this)}
                                    />
                                    { passwordValid &&
                                        <ResetPasswordAdornmentContainer>
                                            <CheckCircleIcon htmlColor="#4BB543" />
                                        </ResetPasswordAdornmentContainer>
                                    }
                                </ForgotPasswordInputContainer>
                                { resetPasswordErrorInputs.confirmPassword.error &&
                                    <ErrorText id="resetpassword-errortext-confirmnewpassword">{resetPasswordErrorInputs.confirmPassword.message}</ErrorText>
                                }
                            </Box>
                            <SubmitButton id="resetpassword-submit-btn" onClick={this._onPressSubmit.bind(this)}>
                                {resetPasswordLoading ?
                                    <CircularProgress size={20} style={{ color: '#FFF' }} /> : "Submit"
                                }
                            </SubmitButton>
                            <Box display="flex" flexDirection="column" alignItems="center" boxSizing="boder-box" width="100%" marginTop="20px">
                                <Box>
                                    <Box fontSize="15px" style={{ opacity: 0.5 }} marginBottom="10px">Password must contain the following</Box>
                                    <Box display="flex" flexDirection="row"  alignItems="center" marginBottom="5px">
                                        {resetPasswordValidMinimum ?
                                            <CheckCircleIcon htmlColor="#4BB543" /> :
                                            <CheckCircleIcon htmlColor="gray" />
                                        }
                                        <Box marginLeft="5px" fontWeight="300">Minimum of 8 Characters</Box>
                                    </Box>
                                    <Box display="flex" flexDirection="row" alignItems="center" marginBottom="5px">
                                        {resetPasswordValidMaximum ?
                                            <CheckCircleIcon htmlColor="#4BB543" /> :
                                            <CheckCircleIcon htmlColor="gray" />
                                        }
                                        <Box marginLeft="5px" fontWeight="300">Maximum of 36 Characters</Box>
                                    </Box>
                                    <Box display="flex" flexDirection="row" alignItems="center" marginBottom="5px">
                                        {resetPasswordValidMixCase ?
                                            <CheckCircleIcon htmlColor="#4BB543" /> :
                                            <CheckCircleIcon htmlColor="gray" />
                                        }
                                        <Box marginLeft="5px" fontWeight="300">Atleast one uppercase and lowercase</Box>
                                    </Box>
                                    <Box display="flex" flexDirection="row" alignItems="center">
                                        {resetPasswordValidNumber ?
                                            <CheckCircleIcon htmlColor="#4BB543" /> :
                                            <CheckCircleIcon htmlColor="gray" />
                                        }
                                        <Box marginLeft="5px" fontWeight="300">Atleast one number</Box>
                                    </Box>
                                </Box>
                            </Box>
                        </ForgotPasswordContent>
                    </ForgotPasswordContainer>
                }
            </Layout>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    system: state.system,
    auth: state.auth
});

const mapDispatchToProps = {
    setAuthState,
    setSystemState,
    newPassword,
    getForgotPasswordId,
    forgotPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);