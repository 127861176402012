import { Component, MouseEvent } from 'react';
import { Link, withRouter, match, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { SystemState } from '../../store/system/types'
import { CartState } from '../../store/cart/types'
import { AuthState } from '../../store/auth/types'
import { setSystemState, getCategories, softRedirect, logout, setInterceptor } from '../../store/system/actions';
import { setAuthState } from '../../store/auth/actions';

import {
    HeaderBar,
    HeaderDetails,
    HeaderContentContainer,
    HeaderContentLogoContainer,
    InputTextField,
    HeaderCategories,
    CategoryTab,
    HeaderCartContainer,
    LowerCategoryBox,
    CategoryProductImageContainer
} from './CommonComponents';
import Drawer from './Drawer';
import HeaderPopOver from './HeaderPopOver';

import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Search from '@material-ui/icons/Search';
import Popover from '@material-ui/core/Popover';

import { ReactComponent as Logo } from '../../assets/images/symphonylogo.svg';
import Shoes1 from '../../assets/images/products/shoes-1.png';

import map from 'lodash/map';
import find from 'lodash/find';
import axios from 'axios';

interface RouteParams extends RouteComponentProps {
    match: match;
}

interface HeaderProps {
    logout: typeof logout;
    softRedirect: typeof softRedirect;
    getCategories: typeof getCategories;
    setSystemState: typeof setSystemState;
    setAuthState: typeof setAuthState;
    setInterceptor: typeof setInterceptor;
    system: SystemState;
    auth: AuthState;
    cart: CartState;
}

class Header extends Component<HeaderProps & RouteParams> {

    componentDidMount = () => {
        const params = new URLSearchParams(this.props.location.search);
        this.props.getCategories();
        if (params.get('category')) {
            // load products
        }

        if (this.props.system.session?.userDetails && this.props.auth.isLoggedIn) {
            // @ts-ignore
            const { handlers } = axios.interceptors.request;
            if (handlers.length === 0) {
                this.props.setInterceptor();
            }
        }
    };

    _onTabClick = (value: string) => {
        this.props.setSystemState({ activeCategory: value });
        this.props.softRedirect(`/products?category=${value}`);   
    };

    _onSelectH1 = (h1: string) => {
        this.props.setSystemState({ 
            activeCategory: h1, 
            popoverAnchorEl: null,
            shallRedirect: true,
            redirectTo: `/search?q=${h1}`
        });
    };

    _onCategoryClick = (h1: string, h2?: string, h3?: string) => {
        this.props.setSystemState({ 
            activeCategory: h1, 
            popoverAnchorEl: null,
            shallRedirect: false,
            redirectTo: `/product?h1=${h1}${h2?`&h2=${h2}${h3 ? `&h3=${h3}` : ''}`:''}`
        });
    };

    _onMouseHover = (data: string, event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
        const selectedCategory = find(this.props.system.categories, {'name' : data})
        this.props.setSystemState({
            popoverAnchorEl: event.currentTarget,
            activeCategory: data,
            selectedCategory
        })
    };

    _onMouseLeave = () => {
        this.props.setSystemState({
            popoverAnchorEl: null
        })
    };
    
    _onPressLogout = () => this.props.logout();

    render = () => {
        const { categories, activeCategory, categoriesLoading, session, popoverAnchorEl, selectedCategory, headerCategoryIsVisible } = this.props.system;
        let cartItemsLength = 0;
        
        for (const key in this.props.cart.cartItems) {
            cartItemsLength += this.props.cart.cartItems[key].quantity
        }

        return (
            <HeaderBar className="header-container">
                <HeaderDetails>
                    <Box display="flex" flexDirection="row">
                        <Box marginRight="36px" display="flex" flexDirection="row" alignItems="center">
                            <InfoOutlinedIcon style={{ marginRight: 8 }} />
                            info@suites.digital.com
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <PhoneOutlinedIcon style={{ marginRight: 8 }} />
                            Tel: (65) 909090909
                        </Box>
                    </Box>
                    { this.props.auth.isLoggedIn ?
                        <HeaderPopOver 
                            firstName={session?.userDetails.firstName} 
                            lastName={session?.userDetails.lastName}
                            image={session?.userDetails.avatar?.path}
                            email={session?.userDetails.email}
                            onPressLogout={this._onPressLogout.bind(this)}
                        />
                        :
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <PersonOutlineOutlinedIcon />
                            <Link to="/register">Register</Link>
                            /
                            <Link to="/login">Login</Link>
                        </Box>
                    }
                </HeaderDetails>
                <HeaderContentContainer>
                    <HeaderContentLogoContainer marginRight="36px">
                        <Logo />
                    </HeaderContentLogoContainer>
                    <InputTextField
                        style={{ width: '100%' }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><Search /></InputAdornment>
                        }}
                    />
                    <Box display="flex" flexDirection="row" alignItems="center" fontSize="12px" boxSizing="border-box" paddingLeft="16px">
                        <Box display="flex" flexDirection="row" alignItems="center" marginRight="16px">
                            <FavoriteBorderIcon style={{ marginRight: 8 }} />
                            <Box width="40px">4 Items</Box>
                        </Box>
                        <HeaderCartContainer>
                            <Link to={this.props.auth.isLoggedIn ? '/cart' : '/login'}>
                                <ShoppingCartOutlinedIcon style={{ marginRight: 8 }} />
                                <Typography>{cartItemsLength} {cartItemsLength > 1 ? 'Items' : 'Item'}</Typography>
                            </Link>
                        </HeaderCartContainer>  
                    </Box>
                </HeaderContentContainer>
                { headerCategoryIsVisible &&
                    <HeaderCategories>
                        {categoriesLoading ? <CircularProgress style={{ width: 24, height: 24 }} /> :
                            <Tabs 
                                variant="scrollable"
                                scrollButtons="off"
                                value={activeCategory}
                                TabIndicatorProps={{ style: { height: 4, backgroundColor: '#4C89F5' }}}
                            >
                                <CategoryTab
                                    id="home-tab"
                                    key="Home"
                                    label="Home"
                                    value="Home"
                                    onClick={this._onTabClick.bind(this, 'Home')}
                                />
                                {map(categories, (cat) => (
                                    <CategoryTab
                                        id={`${cat.name.replace(/ +/g, "_")}-tab`}
                                        key={cat.name}
                                        label={cat.name}
                                        value={cat.name}
                                        onClick={this._onSelectH1.bind(this, cat.name)}
                                        onMouseEnter={this._onMouseHover.bind(this, cat.name)}
                                    />
                                ))}
                            </Tabs>
                        }
                        {selectedCategory &&
                            <Popover
                                style={{pointerEvents: 'none'}}
                                id='category-popover'
                                open={popoverAnchorEl !== null 
                                    && selectedCategory.h2 
                                    && selectedCategory.h2.length > 0
                                }
                                anchorEl={popoverAnchorEl}
                                PaperProps={{
                                    style: {
                                        pointerEvents: 'auto'
                                    },
                                    onMouseLeave: this._onMouseLeave.bind(this)
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center'
                                }}
                                onClose={this._onMouseLeave.bind(this)}
                                disablePortal={true}
                                disableAutoFocus={true}
                                disableEnforceFocus={true}
                                disableRestoreFocus={true}
                            >
                                <Box display='flex' justifyContent="space-between" style={{width: '100vw'}}>
                                    <LowerCategoryBox>
                                        {map(selectedCategory.h2, (cat) => {
                                            let lowerCategory: any = []
                                            lowerCategory.push(<Link 
                                                to={`/product?h1=${selectedCategory.name}&h2=${cat.name}`}
                                                onClick={this._onCategoryClick.bind(this, selectedCategory.name, cat.name, '')}
                                                style={{fontWeight: 'bolder', marginRight: 50, marginBottom: 30, justifyContent: 'left'}}>{cat.name}
                                            </Link>)
                                            lowerCategory.push(map(cat.h3, (h3) => {
                                                return (<Link 
                                                    to={`/product?h1=${selectedCategory.name}&h2=${cat.name}&h3=${h3}`}
                                                    onClick={this._onCategoryClick.bind(this, selectedCategory.name, cat.name, h3)}
                                                    style={{marginRight: 50, marginBottom: 30, justifyContent: 'left'}}
                                                >
                                                    {h3}
                                                </Link>)
                                            }))
                                            return lowerCategory;
                                        })}
                                    </LowerCategoryBox>
                                    <Box display="flex" flexDirection="column" justifyContent="space-between" paddingBottom="110px" padding="60px" paddingLeft="80px" width="20%">
                                        <Box fontWeight="bolder">New for you</Box>
                                        <CategoryProductImageContainer><img src={Shoes1} alt="" /> </CategoryProductImageContainer>
                                        <Box>Nike Run</Box>
                                        <Box fontWeight="bolder">Php 2,000.00</Box>
                                    </Box>
                                </Box>
                            </Popover>
                        }
                    </HeaderCategories>
                }
                {/* Drawer */}
                <Drawer 
                    firstName={session?.userDetails.firstName} 
                    lastName={session?.userDetails.lastName}
                    email={session?.userDetails.email}
                    categories={categories}
                    onHomeClick={this._onTabClick.bind(this)}
                    onCategorySelect={this._onSelectH1.bind(this)}
                    onRedirectClick={() => {
                        this.props.setSystemState({
                            shallRedirect: true,
                            redirectTo: '/login'
                        })
                    }}
                    onPressLogout={this._onPressLogout.bind(this)}
                    isLoggedIn={this.props.auth.isLoggedIn}
                />
            </HeaderBar>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    system: state.system,
    auth: state.auth,
    cart: state.cart
});

const mapDispatchToProps = {
    logout,
    setSystemState,
    setAuthState,
    setInterceptor,
    getCategories,
    softRedirect
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));